<template>
  <div>
    <ListProject />
    <div class="welcomeProject">
      <Welcome />
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
const ListProject = () => import('../components/Renew/Project/ListProject.vue');
const Welcome = () => import('../components/Renew/Components/Welcome.vue');
const Footer = () => import('../views/FooterNew.vue');

export default {
  name: 'Projects',
  data() {
    return {};
  },

  created() {
    // this.currImages = this.images.notable;
  },

  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  watch: {
    lang() {},
  },
  methods: {},
  components: { ListProject, Welcome, Footer },
};
</script>

<style lang="scss" scoped>
.welcomeProject {
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
